import * as React from "react"
import {
  createStyles,
  Theme,
  WithStyles,
  withStyles,
  TextField,
  MenuItem,
  Select,
  Button,
  Typography,
  Grid,
  Link
} from "@material-ui/core"
import AppFooter from "../AppFooter"
import AppForm from "../AppForm"
import isEmail from "validator/lib/isEmail"
import isTel from "validator/lib/isMobilePhone"
import { navigate } from "gatsby"
import FormFeedback from "../formFields/FormFeedback"
import { Auth } from "aws-amplify"
import addToMailchimp from "gatsby-plugin-mailchimp"
//@ts-ignore
import telCodes from "./telcodes.json"

const styles = (theme: Theme) =>
  createStyles({
    form: {
      marginTop: theme.spacing(6)
    },
    button: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText
      //fontWeight: "bold"
    },
    reVerifyButton: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
      backgroundColor: theme.palette.grey[100]
    },
    feedback: {
      marginTop: theme.spacing(2)
    },
    dropDown: {
      marginTop: 18
    }
  })

interface lostPasswordProps extends WithStyles<typeof styles> {}

const formVal = {
  value: "",
  isDirty: false,
  errorMessage: ""
}

const LostPassword = (props: lostPasswordProps) => {
  const { classes } = props
  //const [telCode, setTelCode] = React.useState("+1")
  const [submitDisabled, setSubmitDisabled] = React.useState(true)
  const [formFields, setFormFields] = React.useState({
    email: { ...formVal },
    password: { ...formVal },
    verificationCode: { ...formVal }
  })
  const [feedBackError, setFeedBackError] = React.useState("")
  const [feedBackSuccess, setFeedBackSuccess] = React.useState("")
  const [verificationSent, setVerificationSent] = React.useState(false)
  const [formDisabled, setFormDisabled] = React.useState(false)

  React.useEffect(() => {
    let formIsValid = true
    for (let key in formFields) {
      if (key === "verificationCode" && !verificationSent) {
        continue
      }
      if (!!formFields[key].errorMessage || !formFields[key].value) {
        formIsValid = false
        break
      }
    }
    setSubmitDisabled(!formIsValid)
  }, [formFields])

  const formHandler = e => {
    const newForState = {
      ...formFields,
      [e.target.name]: {
        value: e.target.value.trim(),
        isDirty: true,
        errorMessage: validate(e.target.name, e.target.value)
      }
    }
    setFormFields(newForState)
  }

  const validate = (name: string, value: string) => {
    if (["email", "verificationCode"].indexOf(name) >= 0) {
      if (value.length == 0) return "field required"
      if (name == "email") {
        return !isEmail(value.trim()) ? "Invalid email" : null
      }
    }
    return ""
  }

  const sendVerification = async () => {
    debugger
    try {
      await Auth.forgotPassword(formFields.email.value)
      setFeedBackError("")
      setFeedBackSuccess("Verification Code Sent")
      setVerificationSent(true)
    } catch (err) {
      console.log(err)
      setFeedBackError(err.message)
      setFeedBackSuccess("")
    }
  }

  const submit = async () => {
    try {
      await Auth.forgotPasswordSubmit(
        formFields.email.value,
        formFields.verificationCode.value,
        formFields.password.value
      )
      setFeedBackError("")
      setFeedBackSuccess("password change success")
      navigate("/sign-in")
    } catch (err) {
      setFeedBackError(err.message)
      setFeedBackSuccess("")
    }
  }

  return (
    <React.Fragment>
      <AppForm>
        <Typography variant="h3" gutterBottom align="center">
          Forgot Password
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            autoComplete="email"
            disabled={formDisabled}
            placeholder="Email"
            label={formFields.email.errorMessage || "Email"}
            error={!!formFields.email.errorMessage}
            onChange={formHandler}
            onBlur={formHandler}
            margin="normal"
            name="email"
            required
            fullWidth
          />
          <Button className={classes.button} fullWidth onClick={sendVerification}>
            Send Verification Code
          </Button>
          {verificationSent && (
            <>
              <TextField
                placeholder="Verification Code"
                label={formFields.verificationCode.errorMessage || "Verification Code"}
                error={!!formFields.verificationCode.errorMessage}
                onChange={formHandler}
                onBlur={formHandler}
                fullWidth
                name="verificationCode"
                type="text"
                required
              />
              <TextField
                placeholder="New Password"
                label={formFields.password.errorMessage || "New Password"}
                error={!!formFields.password.errorMessage}
                onChange={formHandler}
                onBlur={formHandler}
                fullWidth
                disabled={formDisabled}
                required
                name="password"
                autoComplete="current-password"
                type="password"
                margin="normal"
              />
              <Button className={classes.button} disabled={submitDisabled} fullWidth onClick={submit}>
                Change Password
              </Button>
            </>
          )}
        </form>
        <FormFeedback error={!!feedBackError} className={classes.feedback} success={!!feedBackSuccess}>
          {feedBackError || feedBackSuccess}
        </FormFeedback>
      </AppForm>
      <AppFooter />
    </React.Fragment>
  )
}
export default withStyles(styles)(LostPassword)
